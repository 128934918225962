export const LoveButton = ({ isLoved }) => {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill={isLoved ? "true" : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Love Button">
        <path
          id="Vector"
          d="M5.22498 7.10779C4.75488 7.57789 4.38197 8.13598 4.12755 8.75019C3.87313 9.36441 3.74219 10.0227 3.74219 10.6875C3.74219 11.3524 3.87313 12.0107 4.12755 12.6249C4.38197 13.2391 4.75488 13.7972 5.22498 14.2673L13.8672 22.9095L22.5095 14.2673C23.4589 13.3179 23.9923 12.0302 23.9923 10.6875C23.9923 9.34488 23.4589 8.0572 22.5095 7.10779C21.5601 6.15839 20.2724 5.62501 18.9297 5.62501C17.5871 5.62501 16.2994 6.15839 15.35 7.10779L13.8672 8.59054L12.3845 7.10779C11.9144 6.63769 11.3563 6.26478 10.7421 6.01037C10.1279 5.75595 9.46955 5.625 8.80473 5.625C8.13991 5.625 7.4816 5.75595 6.86738 6.01037C6.25317 6.26478 5.69508 6.63769 5.22498 7.10779V7.10779Z"
          stroke={isLoved ? "#0000" : "#9E9E9E"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default LoveButton;
