import React from "react";

export const Logo = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="234"
      height="59"
      viewBox="0 0 234 59"
      fill="none"
      className={className}
    >
      <path
        d="M148.022 3.02467L130.794 45.8833H133.616L139.078 32.1373H159.607L165.009 45.8833H167.83L150.903 3.02467H148.022ZM139.918 29.8563L149.462 5.6058H149.583L158.766 29.8563H139.918Z"
        fill="black"
      />
      <path
        d="M187.947 24.0338H172.761V5.18561H187.947C196.111 5.18561 198.992 8.8472 198.992 13.9494C198.992 21.0925 194.73 24.0338 187.947 24.0338ZM190.588 26.2548C190.588 26.2548 201.513 25.5945 201.513 13.9494C201.513 5.78587 195.39 3.02467 187.947 3.02467H169.999V45.8833H172.761V26.3748H188.127L199.232 45.8833H202.053L190.588 26.2548Z"
        fill="black"
      />
      <path
        d="M215.867 5.30566V45.8833H218.508V5.30566H233.635V3.02467H200.8V5.30566H215.867Z"
        fill="black"
      />
      <path
        d="M4.11563 45.8828H31.0673V43.6018H6.75678V3.02418H4.11563V45.8828Z"
        fill="#9E9E9E"
      />
      <path
        d="M67.1566 24.454C67.1566 11.9085 59.5933 2.00423 46.8078 2.00423C33.9622 2.00423 26.3989 11.9085 26.3989 24.454C26.3989 36.9994 33.9622 46.9037 46.8078 46.9037C59.5933 46.9037 67.1566 36.9994 67.1566 24.454ZM29.04 24.454C29.04 13.4692 35.5829 4.28522 46.8078 4.28522C57.9726 4.28522 64.5155 13.4692 64.5155 24.454C64.5155 35.4388 57.9726 44.6228 46.8078 44.6228C35.5829 44.6228 29.04 35.4388 29.04 24.454Z"
        fill="#9E9E9E"
      />
      <path
        d="M66.707 3.02467H63.9458L79.9127 45.8833H82.974L99.001 3.02467H96.3599L81.5334 43.6023H81.4134L66.707 3.02467Z"
        fill="#9E9E9E"
      />
      <path
        d="M102.848 43.6023V24.7541H127.458V22.4731H102.848V5.30566H129.019V3.02467H100.206V45.8833H129.319V43.6023H102.848Z"
        fill="#9E9E9E"
      />
    </svg>
  );
};
